import { Case, Marker } from "../interfaces";
// import { Images } from '../constants/Images';
var Tiff = require("tiff.js");
const getImageData = (file: Blob): Promise<Uint16Array> => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    // console.log("file---: ", file);
    reader.readAsArrayBuffer(file);
    reader.onload = (res: any) => {
      // console.log("res---: ", res);
      const uint8View = new Uint8Array(res.target.result);
      let array = new Uint16Array(uint8View);
      resolve(array);
    };
    reader.onerror = function (error) {
      // console.log("Error: ", error);
      reject(error);
    };
  });
};

const loadImageFilter = (url: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onerror = reject;
    img.onload = () => resolve(img);
    img.crossOrigin = "anonymous";
    img.src = url;
  });
};

const loadImageUrlZeff = (url: string): Promise<HTMLCanvasElement> => {
  return new Promise((resolve, reject) => {
    const dotIndex = url.lastIndexOf(".");
    const extention = url.substring(dotIndex);
    if (
      extention !== ".png" &&
      extention !== ".BMP" &&
      extention !== ".jpg" &&
      extention !== ".JPG" &&
      extention !== ".tif"
    ) {
      return;
    }
    // console.log('url: ', url);
    // console.log('extention: ', extention);

    var request = new XMLHttpRequest();
    request.open("GET", url, true);
    // request.setRequestHeader('Access-Control-Allow-Origin', '*');
    request.responseType = "blob";
    request.onload = function () {
      var reader = new FileReader();
      // console.log('request.response: ', request);
      if (
        extention === ".png" ||
        extention === ".BMP" ||
        extention === ".jpg" ||
        extention === ".JPG"
      ) {
        reader.readAsDataURL(request.response);
      } else {
        reader.readAsArrayBuffer(request.response);
      }

      // reader.readAsDataURL(request.response);
      reader.onload = function (e: any) {
        // console.log('extention', extention)
        if (
          extention === ".png" ||
          extention === ".BMP" ||
          extention === ".jpg" ||
          extention === ".JPG"
        ) {
          let canvas = document.createElement("canvas") as HTMLCanvasElement;

          // console.log('dotIndex', e)
          let context = canvas.getContext("2d");

          const image = new Image();
          image.src = e.target.result;

          image.onload = function () {
            if (context) {
              // console.log('e.target.result url----  ', url);
              // console.log('e.target.result ', e.target.result);
              // console.log('image ', image.height);
              context.drawImage(image, 0, 0);
              resolve(canvas);
            }
          };
          image.onerror = function (error) {
            console.log("Error png: ", error);
            reject(error);
          };
        }

        if (extention === ".tif") {
          // console.log('dotIndex tif', e)
          if (e.target.result !== null && e.target.result !== this.EMPTY) {
            try {
              Tiff.initialize({ TOTAL_MEMORY: 19777216 * 10 }); // Initialize the memory with 197/2 MB
              let imageTif = new Tiff({ buffer: e.target.result });
              imageTif.setDirectory(0);
              resolve(imageTif.toCanvas());
            } catch (error) {
              reject(error);
            }
          }

          // if (image1.countDirectory() > 1 && index === 1) {
          //   image1.setDirectory(1);
          //   resolve(image1.toCanvas())
          // } else {
          //   // imgs.push(image1.toCanvas());
          //   image1.setDirectory(0);
          //   resolve(image1.toCanvas())
          // }
        }

        // resolve(image1)
      };
      reader.onerror = function (error) {
        // console.log("Error reader: ", error);
        reject(error);
      };
    };
    request.onerror = function (error) {
      // console.log("Error request: ", error);
      reject(error);
    };
    try {
      request.send();
    } catch (error) {
      reject(error);
    }
  });
};

const loadImageUrlZeff222 = (url: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    const dotIndex = url.lastIndexOf(".");
    const extention = url.substring(dotIndex);
    if (extention !== ".pdf") {
      return;
    }
    // console.log('url: ', url);
    // console.log('extention: ', extention);

    var request = new XMLHttpRequest();
    request.open("GET", url, true);
    // request.setRequestHeader('Access-Control-Allow-Origin', '*');
    request.responseType = "blob";
    request.onload = function () {
      // resolve(request.response);

      var reader = new FileReader();
      reader.readAsDataURL(request.response);
      // console.log('request.response: ', request);
      // if (extention === '.pdf') {

      // } else {
      // 	reader.readAsArrayBuffer(request.response);
      // }

      // reader.readAsDataURL(request.response);
      reader.onload = function (e: any) {
        // console.log('extention', extention)
        resolve(e.target.result);
      };
      reader.onerror = function (error) {
        // console.log("Error reader: ", error);
        reject(error);
      };
    };
    request.onerror = function (error) {
      // console.log("Error request: ", error);
      reject(error);
    };
    request.send();
  });
};

const loadImageUrl = (url: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = "anonymous";
    // console.log('url: ', url);
    image.src = url;
    // resolve(image)
    image.onload = function () {
      resolve(image);
    };
    image.onerror = function (error) {
      // console.log("Errorwwww: ", error);
      reject(error);
    };
  });
};

// const loadImageUrl2Layer = (url: string): Promise<any> => {
//   return new Promise((resolve, reject) => {
//     var gl = createContext(canvas, render)
//     var obj = {
//       image: new Image(),
//       texture: null
//     };

//     obj.image.crossOrigin = "anonymous";
//     obj.image.src = url;
//     // resolve(image)
//     obj.image.onload = function () {
//       obj.texture = createTex2d(gl, obj.image)
//       obj.texture.minFilter = obj.texture.magFilter = gl.LINEAR;
//       resolve(obj)
//     };
//     obj.image.onerror = function (error) {
//       console.log('Error: ', error);
//       reject(error)
//     }
//   })
// }

const getMarkerImage = (): HTMLImageElement => {
  const image = new Image();
  image.crossOrigin = "anonymous";
  image.src = "http://www.clker.com/cliparts/w/O/e/P/x/i/map-marker-hi.png";
  return image;
};

const getTimeTotalPractise = (timers: any): number => {
  try {
    if (timers.length) return timers[timers.length - 1];
    return 0;
  } catch (error) {
    return 0;
  }
};

const toHHMMSS = (secs: any) => {
  try {
    var sec_num = parseInt(secs, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor(sec_num / 60) % 60;
    var seconds = sec_num % 60;
    return [hours, minutes, seconds]
      .map((v) => (v < 10 ? "0" + v : v))
      .join(":");
  } catch (error) {
    return "";
  }
};

const toMM = (secs: any) => {
  var sec_num = parseInt(secs, 10);
  var minutes = Math.round(sec_num / 60);
  return minutes;
};
const insidePolygon = (markerX: number, markerY: number, vs: any) => {
  var inside = false;
  for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    var xi = vs[i].x,
      yi = vs[i].y;
    var xj = vs[j].x,
      yj = vs[j].y;

    var intersect =
      yi > markerY != yj > markerY &&
      markerX < ((xj - xi) * (markerY - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  return inside;
};

const checkMarkerCorrect = (
  caseData: Case,
  marker: Marker,
  secondImageHeight: number,
  canvasHeight: number
): Marker => {
  const { objs } = caseData;
  objs.forEach((obj: any, index: number) => {
    const point1 = JSON.parse(obj.point1.toString());
    const point2 = JSON.parse(obj.point2.toString());

    const { firstImageWidth, isFirstImage } = marker;
    const [posX, posY] = [marker.x, marker.y];
    if (
      // posX >= point1[0].x &&
      // posX <= point1[2].x &&
      // posY >= point1[0].y &&
      // posY <= point1[2].y &&
      insidePolygon(posX, posY, point1) &&
      isFirstImage
    ) {
      marker.indexObjCorrect = index;
      marker.isCorrect = true;
    }

    const newPosY = posY - (canvasHeight - secondImageHeight);
    const newPosX = posX - firstImageWidth;

    if (
      // posX >= firstImageWidth + point2[0].x &&
      // posX <= firstImageWidth + point2[2].x &&
      // posY >= canvasHeight - secondImageHeight + point2[0].y &&
      // posY <= canvasHeight - secondImageHeight + point2[2].y &&
      insidePolygon(newPosX, newPosY, point2) &&
      !isFirstImage
    ) {
      marker.indexObjCorrect = index;
      marker.isCorrect = true;
    }

    if (marker.indexObjCorrect !== undefined) return;
  });
  return marker;
};

const toDate = (dataString: string) => {
  const dataSplip = dataString.split(".");
  const newDate = new Date(
    dataSplip[0] + "/" + dataSplip[1] + "/" + dataSplip[2]
  );
  return dataSplip[1] + "/" + dataSplip[0] + "/" + dataSplip[2];
};

const getDisable = (
  role: string,
  fieldName: string,
  action: string
): boolean => {
  if (
    role === "Supervisor" &&
    fieldName === "employeeid" &&
    action === "update"
  )
    return true;
  if (
    role === "Supervisor" &&
    fieldName === "profileOption" &&
    action === "update"
  )
    return true;
  if (role === "Supervisor" && fieldName === "firstname" && action === "update")
    return true;
  if (role === "Supervisor" && fieldName === "lastname" && action === "update")
    return true;
  if (role === "Supervisor" && fieldName === "email" && action === "update")
    return true;
  if (
    role === "Supervisor" &&
    fieldName === "statusOption" &&
    action === "update"
  )
    return true;
  if (
    role === "Supervisor" &&
    fieldName === "dateofbirth" &&
    action === "update"
  )
    return true;
  if (
    role === "Supervisor" &&
    fieldName === "countryOption" &&
    action === "update"
  )
    return true;
  if (role === "Supervisor" && fieldName === "phone" && action === "update")
    return true;
  // if (role === 'Supervisor' && fieldName === 'username' && action === 'update') return true;
  if (role === "Supervisor" && fieldName === "employer" && action === "update")
    return true;
  if (role === "Supervisor" && fieldName === "airport" && action === "update")
    return true;

  if (
    role === "Supervisor" &&
    fieldName === "profileOption" &&
    action === "new"
  )
    return true;
  if (role === "Supervisor" && fieldName === "statusOption" && action === "new")
    return true;
  if (role === "Supervisor" && fieldName === "dateofbirth" && action === "new")
    return true;
  if (
    role === "Supervisor" &&
    fieldName === "countryOption" &&
    action === "new"
  )
    return true;
  if (role === "Supervisor" && fieldName === "phone" && action === "new")
    return true;
  // if (role === 'Supervisor' && fieldName === 'username' && action === 'new') return true;
  if (role === "Supervisor" && fieldName === "employer" && action === "new")
    return true;

  if (fieldName === "username") return true;
  return false;
};

// const demoUrl1 = (indexCase: number) => {
//   if (indexCase === 0) {
//     return [
//       Images.id_95900_a_0,
//       Images.TEST_S_A,
//       Images.id_95900_c_0,
//       Images.TEST_S_C,
//     ];
//   }

//   if (indexCase === 1) {
//     return [
//       Images.id_52200_a_1,
//       Images.TEST_S_A,
//       Images.id_52200_c_1,
//       Images.TEST_S_C,
//     ];
//   }

//   if (indexCase === 2) {
//     return [
//       Images.id_47000_a_2,
//       Images.TEST_S_A,
//       Images.id_47000_c_2,
//       Images.TEST_S_C,
//     ];
//   }

//   if (indexCase === 3) {
//     return [
//       Images.id_23400_a_3,
//       Images.TEST_S_A,
//       Images.id_23400_c_3,
//       Images.TEST_S_C,
//     ];
//   }

//   if (indexCase === 4) {
//     return [
//       Images.id_62700_a_4,
//       Images.TEST_S_A,
//       Images.id_62700_c_4,
//       Images.TEST_S_C,
//     ];
//   }

//   if (indexCase === 5) {
//     return [
//       Images.id_04300_a_5,
//       Images.TEST_S_A,
//       Images.id_04300_c_5,
//       Images.TEST_S_C,
//     ];
//   }

//   if (indexCase === 6) {
//     return [
//       Images.id_11500_a_6,
//       Images.TEST_S_A,
//       Images.id_11500_c_6,
//       Images.TEST_S_C,
//     ];
//   }

//   if (indexCase === 7) {
//     return [
//       Images.id_08500_a_7,
//       Images.TEST_S_A,
//       Images.id_08500_c_7,
//       Images.TEST_S_C,
//     ];
//   }

//   if (indexCase === 8) {
//     return [
//       Images.id_62400_a_8,
//       Images.TEST_S_A,
//       Images.id_62400_c_8,
//       Images.TEST_S_C,
//     ];
//   }

//   if (indexCase === 9) {
//     return [
//       Images.id_50000_a_9,
//       Images.TEST_S_A,
//       Images.id_50000_c_9,
//       Images.TEST_S_C,
//     ];
//   }

//   if (indexCase === 10) {
//     return [
//       Images.id_57700_a_10,
//       Images.TEST_S_A,
//       Images.id_57700_c_10,
//       Images.TEST_S_C,
//     ];
//   }

//   if (indexCase === 11) {
//     return [
//       Images.id_8_04300_a_11,
//       Images.TEST_S_A,
//       Images.id_8_04300_c_11,
//       Images.TEST_S_C,
//     ];
//   }

//   if (indexCase === 12) {
//     return [
//       Images.id_67400_a_12,
//       Images.TEST_S_A,
//       Images.id_67400_c_12,
//       Images.TEST_S_C,
//     ];
//   }

//   if (indexCase === 13) {
//     return [
//       Images.id_07900_a_13,
//       Images.TEST_S_A,
//       Images.id_07900_c_13,
//       Images.TEST_S_C,
//     ];
//   }

//   if (indexCase === 14) {
//     return [
//       Images.id_20230120_174601_50900_a_14,
//       Images.TEST_S_A,
//       Images.id_20230120_174601_50900_c_14,
//       Images.TEST_S_C,
//     ];
//   }

//   if (indexCase === 15) {
//     return [
//       Images.id_20230120_174903_47600_a_15,
//       Images.TEST_S_A,
//       Images.id_20230120_174903_47600_c_15,
//       Images.TEST_S_C,
//     ];
//   }

//   if (indexCase === 16) {
//     return [
//       Images.id_20230120_175552_33200_a_16,
//       Images.TEST_S_A,
//       Images.id_20230120_175552_33200_c_16,
//       Images.TEST_S_C,
//     ];
//   }

//   if (indexCase === 17) {
//     return [
//       Images.id_20230127_93500_a_17,
//       Images.TEST_S_A,
//       Images.id_20230127_93500_c_17,
//       Images.TEST_S_C,
//     ];
//   }

//   if (indexCase === 18) {
//     return [
//       Images.id_20230127_2300_a_18,
//       Images.TEST_S_A,
//       Images.id_20230127_2300_c_18,
//       Images.TEST_S_C,
//     ];
//   }

//   return [
//     Images.id_95900_a_0,
//     Images.TEST_S_A,
//     Images.id_95900_c_0,
//     Images.TEST_S_C,
//   ];
// };

// const demoUrl2 = (indexCase: number) => {
//   if (indexCase === 0) {
//     return [
//       Images.id_95900_a_11,
//       Images.TEST_S_A,
//       Images.id_95900_c_11,
//       Images.TEST_S_C,
//     ];
//   }

//   if (indexCase === 1) {
//     return [
//       Images.id_52200_a_22,
//       Images.TEST_S_A,
//       Images.id_52200_c_22,
//       Images.TEST_S_C,
//     ];
//   }

//   if (indexCase === 2) {
//     return [
//       Images.id_47000_a_33,
//       Images.TEST_S_A,
//       Images.id_47000_c_33,
//       Images.TEST_S_C,
//     ];
//   }

//   if (indexCase === 3) {
//     return [
//       Images.id_23400_a_44,
//       Images.TEST_S_A,
//       Images.id_23400_c_44,
//       Images.TEST_S_C,
//     ];
//   }

//   if (indexCase === 4) {
//     return [
//       Images.id_62700_a_55,
//       Images.TEST_S_A,
//       Images.id_62700_c_55,
//       Images.TEST_S_C,
//     ];
//   }

//   if (indexCase === 5) {
//     return [
//       Images.id_04300_a_66,
//       Images.TEST_S_A,
//       Images.id_04300_c_66,
//       Images.TEST_S_C,
//     ];
//   }

//   if (indexCase === 6) {
//     return [
//       Images.id_11500_a_77,
//       Images.TEST_S_A,
//       Images.id_11500_c_77,
//       Images.TEST_S_C,
//     ];
//   }

//   if (indexCase === 7) {
//     return [
//       Images.id_08500_a_88,
//       Images.TEST_S_A,
//       Images.id_08500_c_88,
//       Images.TEST_S_C,
//     ];
//   }

//   if (indexCase === 8) {
//     return [
//       Images.id_62400_a_99,
//       Images.TEST_S_A,
//       Images.id_62400_c_99,
//       Images.TEST_S_C,
//     ];
//   }

//   return [
//     Images.id_50000_a_100,
//     Images.TEST_S_A,
//     Images.id_50000_c_100,
//     Images.TEST_S_C,
//   ];
// };

// const demoUrl3 = (indexCase: number) => {
//   if (indexCase === 0) {
//     return [
//       Images.id_95900_a_1111,
//       Images.TEST_S_A,
//       Images.id_95900_c_1111,
//       Images.TEST_S_C,
//     ];
//   }

//   if (indexCase === 1) {
//     return [
//       Images.id_52200_a_2222,
//       Images.TEST_S_A,
//       Images.id_52200_c_2222,
//       Images.TEST_S_C,
//     ];
//   }

//   if (indexCase === 2) {
//     return [
//       Images.id_47000_a_3333,
//       Images.TEST_S_A,
//       Images.id_47000_c_3333,
//       Images.TEST_S_C,
//     ];
//   }

//   if (indexCase === 3) {
//     return [
//       Images.id_23400_a_4444,
//       Images.TEST_S_A,
//       Images.id_23400_c_4444,
//       Images.TEST_S_C,
//     ];
//   }

//   if (indexCase === 4) {
//     return [
//       Images.id_62700_a_5555,
//       Images.TEST_S_A,
//       Images.id_62700_c_5555,
//       Images.TEST_S_C,
//     ];
//   }

//   if (indexCase === 5) {
//     return [
//       Images.id_04300_a_6666,
//       Images.TEST_S_A,
//       Images.id_04300_c_6666,
//       Images.TEST_S_C,
//     ];
//   }

//   if (indexCase === 6) {
//     return [
//       Images.id_11500_a_7777,
//       Images.TEST_S_A,
//       Images.id_11500_c_7777,
//       Images.TEST_S_C,
//     ];
//   }

//   if (indexCase === 7) {
//     return [
//       Images.id_08500_a_8888,
//       Images.TEST_S_A,
//       Images.id_08500_c_8888,
//       Images.TEST_S_C,
//     ];
//   }

//   if (indexCase === 8) {
//     return [
//       Images.id_62400_a_9999,
//       Images.TEST_S_A,
//       Images.id_62400_c_9999,
//       Images.TEST_S_C,
//     ];
//   }

//   return [
//     Images.id_50000_a_10000,
//     Images.TEST_S_A,
//     Images.id_50000_c_10000,
//     Images.TEST_S_C,
//   ];
// };

// const demoUrl4 = (indexCase: number) => {
//   if (indexCase === 0) {
//     return [Images.id_4_a_4, Images.TEST_S_A, Images.id_4_c_4, Images.TEST_S_C];
//   }

//   return [Images.id_4_a_4, Images.TEST_S_A, Images.id_4_c_4, Images.TEST_S_C];
// };

// const demoUrl5 = (indexCase: number) => {
//   if (indexCase === 0) {
//     return [Images.id_5_a_5, Images.TEST_S_A, Images.id_5_c_5, Images.TEST_S_C];
//   }

//   return [Images.id_5_a_5, Images.TEST_S_A, Images.id_5_c_5, Images.TEST_S_C];
// };

const loadTifFile = (url: string): Promise<HTMLCanvasElement> => {
  return new Promise((resolve, reject) => {
    // console.log('url: ', url);
    // console.log('extention: ', extention);

    var request = new XMLHttpRequest();
    request.timeout = 1000000; // time in milliseconds
    request.open("GET", url, true);
    // request.setRequestHeader("Access-Control-Allow-Origin", "*");
    request.responseType = "blob";
    request.onload = function () {
      var reader = new FileReader();
      console.log("request.response -------: ", request.response);
      reader.readAsArrayBuffer(request.response);

      // reader.readAsDataURL(request.response);
      reader.onload = function (e: any) {
        if (e.target.result !== null && e.target.result !== this.EMPTY) {
          try {
            Tiff.initialize({ TOTAL_MEMORY: 19777216 * 10 }); // Initialize the memory with 197/2 MB
            // console.log("e.target.result: ", e.target.result);
            let imageTif = new Tiff({ buffer: e.target.result });
            imageTif.setDirectory(0);
            resolve(imageTif.toCanvas());
          } catch (error) {
            reject(error);
          }
        }
      };
      reader.onerror = function (error) {
        // console.log("Error reader: ", error);
        reject(error);
      };
    };
    request.onerror = function (error) {
      // console.log("Error request: ", error);
      reject(error);
    };
    request.ontimeout = (e) => {
      // console.log("Error request: ", e);
      reject();
    };
    try {
      request.send();
    } catch (error) {
      reject(error);
    }
  });
};

const loadTifFileByBlob = (dataBlob: Blob): Promise<HTMLCanvasElement> => {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsArrayBuffer(dataBlob);
    reader.onload = function (e: any) {
      if (e.target.result !== null && e.target.result !== this.EMPTY) {
        try {
          Tiff.initialize({ TOTAL_MEMORY: 19777216 * 10 }); // Initialize the memory with 197/2 MB
          let imageTif = new Tiff({ buffer: e.target.result });
          imageTif.setDirectory(0);
          resolve(imageTif.toCanvas());
        } catch (error) {
          reject(error);
        }
      }
    };
    reader.onerror = function (error) {
      reject(error);
    };
  });
};

export {
  getImageData,
  loadImageUrl,
  loadImageUrlZeff,
  loadImageUrlZeff222,
  loadImageFilter,
  toHHMMSS,
  getTimeTotalPractise,
  getMarkerImage,
  checkMarkerCorrect,
  toDate,
  getDisable,
  loadTifFile,
  loadTifFileByBlob,
  toMM,
  // demoUrl1,
  // demoUrl2,
  // demoUrl3,
  // demoUrl4,
  // demoUrl5,
};
