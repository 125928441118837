export const jwtName = "accessToken";
export const regionKey = "region";
export const jwtExpiryName = "expiry";
export const userInfoName = "user";
export const tableConfigKey = "tableConfig";
export const chartConfigKey = "chartConfig";
export const languageKey = "language";
export const keyboardKey = "keyboard";
export const personKey = "personStore";
export const i18nextLng = "i18nextLng";
export const timerListKey = "timerList";
export const testResultKey = "testResult";
export const traningListFilterKey = "traningListFilterKey";
export const examListFilterKey = "examListFilterKey";

export const get = (name: string, isParse: boolean = true) => {
  if (name && typeof window !== "undefined") {
    const raw = localStorage.getItem(name);
    if (raw) {
      if (isParse) return JSON.parse(raw);
      return raw;
    }
    return null;
  }
  return null;
};

export const set = (name: string, value: any) => {
  return localStorage.setItem(name, JSON.stringify(value));
};

export const remove = (name: string) => {
  return localStorage.removeItem(name);
};

export const getPersonIdFromStore = () => {
  try {
    return get(personKey).personid;
  } catch (error) {
    window.location.href = "/login";
  }
};
