// import duotone from "../../icons/duotone";
import { icons } from "../../assets/icons";
import ElementHub from "../../icons/duotone/ElementHub";

export const navigations = [
  { type: "label", label: "admin" },
  {
    // title: "Dashboards",
    // type: "extLink",
    // label: "Dashboards",
    name: "Dashboard",
    // Icon: ElementHub,
    // title: "DataTable",
    icon: ElementHub,
    path: "/dashboard/crm",
    // children: [
    //   // { name: "Learning Management", path: "/dashboard" },
    //   // { name: "Job Management", path: "/dashboard/job-management" },
    //   { name: "CRM", path: "/dashboard/crm" },
    //   // { name: "Sales", path: "/dashboard/sales" },
    //   // {
    //   //   name: "Sub Child",
    //   //   path: "/dashboard/sub-child",
    //   //   subChildren: [
    //   //     { name: "Sub Child V1", path: "/dashboard/sub-child-v1" },
    //   //     { name: "Sub Child V2", path: "/dashboard/sub-child-v2" },
    //   //     { name: "Sub Child V3", path: "/dashboard/sub-child-v3" },
    //   //   ],
    //   // },
    //   // { name: "Sales V2", path: "/dashboard/sales-v2" },
    //   // { name: "SaaS", path: "/dashboard/saas" },
    //   // { name: "Project Management", path: "/dashboard/project-management" },
    //   // {
    //   //   name: "Project Management V2",
    //   //   path: "/dashboard/project-management-v2",
    //   // },
    // ],
  },
  {
    name: "practice",
    icon: ElementHub,
    children: [
      { name: "training_list", path: "/training/list" },
      { name: "exam_list", path: "/training/examList" },
      { name: "result_list", path: "/training/report" },
      { name: "summary", path: "/training/summary" },
      { name: "feedback", path: "/training/feedback" },
    ],
  },
  {
    name: "manager",
    icon: ElementHub,
    children: [
      { name: "company_list", path: "/company/list" },
      { name: "user_list", path: "/company/userList" },
      { name: "create_company", path: "/company/createCompany" },
      { name: "download_practise", path: "/company/download" },
    ],
  },
];

export const navigationManagers = [
  { type: "label", label: "manager" },
  {
    name: "Dashboard",
    icon: ElementHub,
    path: "/dashboard/Crm",
  },
  {
    name: "practice",
    icon: ElementHub,
    children: [
      { name: "training_list", path: "/training/list" },
      { name: "exam_list", path: "/training/examList" },
      { name: "result_list", path: "/training/report" },
      { name: "summary", path: "/training/summary" },
    ],
  },
  {
    name: "manager",
    icon: ElementHub,
    children: [
      { name: "company_list", path: "/company/list" },
      { name: "user_list", path: "/company/userList" },
      // { name: "Create Company", path: "/company/createCompany" },
      { name: "download_practise", path: "/company/download" },
    ],
  },
];

export const navigationStudents = [
  { type: "label", label: "student" },
  {
    name: "Dashboard",
    icon: ElementHub,
    path: "/dashboard/crmscreener",
  },
  {
    name: "practice",
    icon: ElementHub,
    children: [
      { name: "training_list", path: "/training/list" },
      { name: "exam_list", path: "/training/examList" },
      // { name: "Report", path: "/training/report" },
    ],
  },
  // {
  //   name: "Manager",
  //   icon: ElementHub,
  //   children: [
  //     { name: "Company List", path: "/company/list" },
  //     { name: "Create Company", path: "/company/createCompany" },
  //     { name: "Download Practise", path: "/company/download" },
  //   ],
  // },
];
